<template>
    <div>
        <template v-if="scrollY_show">
            <van-nav-bar
                    placeholder
                    fixed
                    :border='false'
                    title="福利"
                    class="header welf"
                    z-index="99">
            </van-nav-bar>
        </template>
        <div class="Welfare-bg">
            <div class="Welfare-list">
                <p class="Welfare-title">福利</p>
                <p class="Welfare-list-right">{{base_infoList.coin}}书豆</p>
            </div>
            <div class="week">
                <div class="week-left">
                    <p class="week-left-title">{{base_infoList.d}}</p>
                    <div>
                        <p class="week-time">{{base_infoList.w}}</p>
                        <p class="week-text">{{base_infoList.m}} {{base_infoList.year}}</p>
                    </div>
                </div>
                <p>
                    <img src="../../assets/images/Welfare-ren.png" alt="" class="Welfare-ren">
                </p>
            </div>
        </div>
        <div class="sign-div">

            <div class="sign">
                <template v-for="item in base_infoList.sign_config">
                    <p>{{item.title}}</p>
                </template>
            </div>
            <div class="sign-center">
                <template v-for="(item,index) in base_infoList.sign_config">
                    <p class="" v-if="item.is_type == 0">
                        <img src="../../assets/images/Welfare-cha.png" alt="" class="Welfare-cha">
                    </p>
                    <p class="" v-if="item.is_type == 1">
                        <img src="../../assets/images/Welfare-dui.png" alt="" class="Welfare-cha">
                    </p>
                    <p class="" v-if="item.is_type == 2 &&index != 6">
                        <img src="../../assets/images/Welfare-dou.png" alt="" class="Welfare-cha">
                    </p>
                    <p class="" v-if="index == 6">
                        <img src="../../assets/images/Welfare-bao.png" alt="" class="Welfare-cha">
                    </p>
                    <p class="xian"></p>
                </template>
            </div>
            <div class="sign-button">
                <template v-for="item in base_infoList.sign_config">
                    <p v-if="item.title == base_infoList.w && item.is_type != 1">
                        今日签到可领取 <span>{{item.coin}}书豆</span>
                    </p>
                </template>
            </div>
            <p class="sign-bottom" @click="signClick">
                立即签到
            </p>
            <p class="sign-bottom-text">
                本周签满7天 最多可领 <span>{{base_infoList.sign_total_coin}}书豆</span>
            </p>
        </div>
        <div class="reading div">
            <p class="reading-left">日常福利</p>
            <p class="reading-right"></p>
        </div>
        <template>
            <ad-js name="300x250"></ad-js>
        </template>
        <!-- <div class="reading-list">
            <div v-if="base_infoList.interact_config">
                <div class="reading-list-top">
                    <p class="reading-list-title">{{base_infoList.interact_config[1].title}}</p>
                    <p class="reading-list-right">
                        <img src="../../assets/images/bookbean_icon.png" alt="" class="reading-dou">
                        +{{base_infoList.interact_config[1].coin}}书豆
                    </p>
                </div>
                <p class="reading-list-bottom">邀请好友注册可获得书豆奖励，书豆可提现</p>
            </div>
            <p class="reading-text" @click="invitationClick">去邀请</p>
        </div> -->
        <div class="reading div">
            <p class="reading-left">每日阅读福利</p>
            <p class="reading-right">今日已读：{{base_infoList.today_read_time}}分钟</p>
        </div>
        <div class="reading-bottom">
            <template v-for="item in base_infoList.daily_read_config">
                <div class="reading-list">
                    <div>
                        <div class="reading-list-top">
                            <p class="reading-list-title">累计阅读{{item.key}}</p>
                            <p class="reading-list-right">
                                <img src="../../assets/images/bookbean_icon.png" alt="" class="reading-dou">
                                +{{item.coin}}书豆
                            </p>
                        </div>
                        <p class="reading-list-bottom">阅读时长达到{{item.key}}分钟即可领{{item.coin}}书豆</p>
                    </div>
                    <p class="reading-text reading-text-active" v-if="item.status == 2">已领取</p>
                    <p class="reading-text" v-else @click="libraryClick">去阅读</p>
                </div>
            </template>
        </div>
        <!--        签到成功-->
        <van-overlay :show="show" z-index="99">
            <div class="wrapper">
                <div class="block">
                    <p class="block-title">恭喜您，签到成功</p>
                    <img src="../../assets/images/succes.png" alt="" class="succes-img">
                    <p class="block-text">获得 <span>{{coin}}书豆</span></p>
                    <p class="block-button" @click="successClick">好的</p>
                </div>
            </div>
        </van-overlay>
    </div>
</template>

<script>
    export default {
        name: "Welfare",
        data() {
            return {
                coin: '',
                show: false,
                scrollY_show: false,
                base_infoList: [],
            }
        },
        created() {
            window.addEventListener('scroll', this.handleScroll)
            this.base_info()
        },
        methods: {
            signClick() {
                if (!localStorage.getItem('token')) {
                    this.$toast('请登录')
                    return
                }
                this.$axios.post('/api/welfare/sign', {
                    coin: this.coin
                })
                    .then(res => {
                        this.show = true
                        this.base_info()
                    })
                    .catch(err => {
                        this.$toast(err.response.data.message);
                    })
            },
            base_info() {
                this.$axios.get('/api/welfare/base_info')
                    .then(res => {
                        this.base_infoList = res.data.data
                        this.base_infoList.sign_config.forEach(item => {
                            if (item.title == this.base_infoList.w) {
                                this.coin = item.coin
                            }
                        })
                    })
                    .catch(err => {
                        this.$toast(err.response.data.message);
                    })
            },
            libraryClick() {
                this.$router.push({
                    path: '/library',
                })
            },
            invitationClick() {
                this.$router.push({
                    path: '/invitation',
                })
            },
            handleScroll() {
                let scrollY = document.documentElement.scrollTop
                if (scrollY >= 30) {
                    this.scrollY_show = true
                } else {
                    this.scrollY_show = false
                }
            },
            successClick() {
                this.show = false
            }
        }
    }
</script>

<style scoped>
    .reading-text-active {
        background: #fafafa;
        border: 1px solid #fafafa;
        color: rgba(191, 194, 204, 1) !important;

    }

    .block-button {
        width: 500px;
        height: 80px;
        background: linear-gradient(-90deg, rgba(77, 119, 253, 1) 0%, rgba(119, 151, 255, 1) 100%);
        border-radius: 40px;
        font-size: 30px;
        color: rgba(255, 255, 255, 1);
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        bottom: -40px;
    }

    .block-text {
        font-size: 34px;
        color: #303133;
        margin-top: -30px;
    }

    .block-text span {
        color: #FCAD0F;
    }

    .block-title {
        font-size: 34px;
        color: #303133;
        margin-bottom: 50px;
    }

    .succes-img {
        width: 243px;
        height: 303px;
    }

    .wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
    }

    .block {
        width: 640px;
        height: 557px;
        border-radius: 20px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background-color: #fff;
        position: relative;
    }

    .reading-bottom {
        margin-bottom: 20px;
    }

    .reading-text {
        display: flex;
        align-items: center;
        justify-content: center;
        color: #303133;
        font-size: 26px;
        padding: 10px 24px;
        height: 46px;
        border: 1px solid rgba(191, 194, 204, 1);
        border-radius: 23px;
    }

    .reading-list {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 40px 20px 40px 0;
        margin: 20px 40px 0px;
        box-shadow: 0px 0px 20px 0px rgba(150, 150, 150, 0.08);
        border-radius: 10px;
    }

    .reading-list-bottom {
        font-size: 24px;
        color: #BFC2CC;
        margin-top: 20px;
        padding-left: 15px;
        width: 500px;
    }

    .reading-list-right {
        font-size: 24px;
        color: #FCAD0F;
    }

    .reading-list-title {
        color: #303133;
        font-size: 30px;
        position: relative;
        margin-right: 25px;
    }

    .reading-list-title:after {
        content: '';
        display: block;
        width: 5px;
        height: 18px;
        background: rgba(77, 119, 253, 1);
        border-radius: 2px;
        position: absolute;
        left: -15px;
        top: 5px;
    }

    .reading-list-top {
        display: flex;
        align-items: center;
        padding-left: 15px;
    }

    .reading-dou {
        width: 20px;
        height: 20px;
    }

    .reading-right {
        color: #BFC2CC;
        font-size: 24px;
    }

    .reading-left {
        color: #0A1C33;
        font-size: 34px;
    }

    .reading {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 40px 0;
    }

    .sign-bottom-text {
        color: #BFC2CC;
        font-size: 24px;
        text-align: center;
        margin-top: 20px;
    }

    .sign-bottom-text span {
        color: #FCAD0F;
    }

    .sign-bottom {
        height: 80px;
        background: linear-gradient(-90deg, rgba(77, 119, 253, 1) 0%, rgba(119, 151, 255, 1) 100%);
        border-radius: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;
        font-size: 30px;
        margin: 50px 70px 0;
    }

    .sign-button {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 20px;
        padding: 0 48px 0 52px;
    }

    .sign-button > p > span {
        color: #FCAD0F;
        font-size: 26px;
    }

    .sign-center .xian:last-of-type {
        display: none !important;
    }

    .sign-center {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 20px 75px;
    }

    .Welfare-cha {
        width: 33px;
        height: 33px;
    }

    .xian {
        width: 45px;
        height: 2px;
        background: rgba(191, 194, 204, 1);
        border-radius: 1px;
    }

    .sign {
        display: flex;
        align-items: center;
        justify-content: space-between;
        color: #303133;
        font-size: 24px;
        padding: 5px 70px 0;
    }

    .sign-div {
        margin-bottom: 50px;
    }

    .Welfare-ren {
        position: absolute;
        top: -90px;
        right: 14px;
        width: 333px;
        height: 361px;
    }

    .week-text {
        color: #BFC2CC;
        font-size: 24px;
        margin-top: 10px;
    }

    .week-time {
        color: #4D77FD;
        font-size: 36px;
    }

    .week-left-title {
        font-size: 90px;
        color: #4D77FD;
        font-weight: bold;
        margin-right: 18px;
    }

    .week-left {
        display: flex;
        align-items: center;
    }

    .week {
        border-radius: 20px 20px 0 0;
        background: #fff;
        margin: 8px 40px 0;
        height: 210px;
        padding: 0 40px;
        display: flex;
        justify-content: flex-start;
        position: relative;
    }

    .Welfare-list-right {
        height: 47px;
        background: rgba(255, 253, 255, 1);
        opacity: 0.5;
        border-radius: 24px 0 0 24px;
        font-size: 24px;
        font-weight: 500;
        color: rgba(77, 119, 253, 1);
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 15px 20px;
    }

    .Welfare-list {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 80px 0 40px 40px;
    }

    .Welfare-title {
        color: #FFFFFF;
        font-size: 42px;
    }

    .Welfare-bg {
        height: 384px;
        background: url("../../assets/images/Welfare-bg.png") center center no-repeat;
        background-size: cover;
    }
</style>